import { casinoTabs } from "./casinoTabs.data";

export const EzugiGames = {
  ...casinoTabs.roulettetab,
  ...casinoTabs.baccarattab,
  ...casinoTabs.AndarBahartab,
  ...casinoTabs.TeenPattitab,
  ...casinoTabs.Blackjacktab,
  ...casinoTabs.thirtytwoCardtab,
  ...casinoTabs.sevenLuckytab,
  ...casinoTabs.sicbotab,
  ...casinoTabs.LiveDealer,
  ...casinoTabs.PopularGames,
  ...casinoTabs.SlotGames,
  ...casinoTabs.Slots,

  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },

  card32: {
    href: "/casino/ezugi/card32",
    code: "228002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  AndarBaharEzugi: {
    href: "/casino/ezugi/AndarBaharEzugi",
    code: "228000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  AutoRouleteEzugi: {
    href: "/casino/ezugi/AutoRouleteEzugi",
    code: "5001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  AutoSpeedRouleteEzugi: {
    href: "/casino/ezugi/AutoSpeedRouleteEzugi",
    code: "221002",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  BaccaratEzugi: {
    href: "/casino/ezugi/BaccaratEzugi",
    code: "100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  BetonEzugi: {
    href: "/casino/ezugi/BetonEzugi",
    code: "227101",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  BlackDiamondEzugi: {
    href: "/casino/ezugi/BlackDiamondEzugi",
    code: "225",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  casinoholdEzugi: {
    href: "/casino/ezugi/casinoholdEzugi",
    code: "507000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  cricketEzugi: {
    href: "/casino/ezugi/cricketEzugi",
    code: "45100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  DragonEzugi: {
    href: "/casino/ezugi/DragonEzugi",
    code: "150",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  FortunrBaccaratEzugi: {
    href: "/casino/ezugi/FortunrBaccaratEzugi",
    code: "102",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  lucky7Ezugi: {
    href: "/casino/ezugi/lucky7Ezugi",
    code: "228001",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  namasteRouletteEzugi: {
    href: "/casino/ezugi/namasteRouletteEzugi",
    code: "221005",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  SicboEzugi: {
    href: "/casino/ezugi/SicboEzugi",
    code: "224000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  speedCricketEzugi: {
    href: "/casino/ezugi/speedCricketEzugi",
    code: "101",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  speedrouletteEzugi: {
    href: "/casino/ezugi/speedrouletteEzugi",
    code: "221000",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  teenpatti3Ezugi: {
    href: "/casino/ezugi/teenpatti3Ezugi",
    code: "227100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
  ultimateAndarEzugi: {
    href: "/casino/ezugi/ultimateAndarEzugi",
    code: "228100",
    casino: "ezugi",
    provider: "",
    homeUrl: "",
    cover: { src: "./images/popular/PopularImg3.jpg", alt: "" },
  },
};
