import React, { useState } from "react";
import { Container, Row, Col, Button, Navbar, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import i18next, { t } from "i18next";

import Fbicon from "../assets/images/head-icon/facebook.png";
import Twitter from "../assets/images/head-icon/twitter.png";
import Telegram from "../assets/images/head-icon/telegram.png";
import Instagram from "../assets/images/head-icon/instagram.png";
import Mobile from "../assets/images/head-icon/mobile.png";

import CricketImgHover from "../assets/images/games/Cricket.png";
import SoccerImgHover from "../assets/images/games/Soccer.png";
import TennisImgHover from "../assets/images/games/Tennis.png";
import InplayImgHover from "../assets/images/games/Inplay.png";
import Vivo from "../assets/images/games/Vivo.png";
import Supernowa from "../assets/images/games/Supernowa.png";
import Ezugi from "../assets/images/games/ezugi.png";
import Evolution from "../assets/images/games/Evolution.png";

import AppDownload from "../assets/images/icons/download1.png";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };
  return (
    <header className="header bfrlgn">
      <div className="top-head">
        <div className="tophead-left">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Fbicon} />
                <p>Facebook</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                // href={
                //   appDetails?.TWITTER != ""
                //     ? appDetails?.TWITTER
                //     : "javascript:void(0)"
                // }
                // target={appDetails?.TWITTER != "" ? "_blank" : ""}
              >
                <img src={Twitter} />
                <p>Twitter</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} />
                <p>Instagram</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} />
                <p>Telegram Channel</p>
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <img src={Mobile} />
                <p>Message Us</p>
              </a>
            </li>
          </ul>
        </div>
        <div className="tophead-right d-none">
          <div className="time-date">
            <span>May 26, 2023 at 1:57:22 PM</span>
          </div>
          <div className="language">
            <div className="language-option">
              <select
                className="language-select btn-outline-secondary beforelogin-language"
                value={window.localStorage.i18nextLng}
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="arab">عربى</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center nav-head">
        <div className="d-flex align-items-center">
          <div className="logo">
            <a href="/">
              {appDetails?.LOGO_URL && (
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              )}
            </a>
          </div>
        </div>
        <div className="headerRight">
          {/* <li className="" href="">
            <a
              href="./images/app-debug_sportInfobuild_7_!0pm.app"
              download="app-debug_sportInfobuild_7_!0pm.apk"
              className="btn-primary btn"
            >
              App <img src={AppDownload} />
            </a>
            <Button variant="primary deposit-btn w-100"></Button>
          </li> */}
          <Button
            variant=""
            onClick={() => {
              navigate("/sign-in");
            }}
            className="btn-login ms-2"
          >
            Login
          </Button>
          <Button
            variant=""
            onClick={() => {
              navigate("/register");
            }}
            className="btn-register ms-2"
          >
            Sign Up
          </Button>
        </div>
      </div>

      <div className="bottom_head">
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <li>
                <Nav.Link href="/sportsbook">Exchange </Nav.Link>
                <div className="on-hover-section">
                  <div className="sports-hover-section">
                    <ul>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "2" },
                            });
                          }}
                        >
                          <span>Cricket</span>
                          <img src={CricketImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "3" },
                            });
                          }}
                        >
                          <span>Soccer</span>
                          <img src={SoccerImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "4" },
                            });
                          }}
                        >
                          <span>Tennis</span>
                          <img src={TennisImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "1" },
                            });
                          }}
                        >
                          <span>Inplay</span>
                          <img src={InplayImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              {/* <li>
                <Nav.Link href="/betby">Sportsbook</Nav.Link>
              </li> */}
              <li>
                <Nav.Link href="/casino/ezugi">Casino</Nav.Link>
                <div className="on-hover-section">
                  <div className="sports-hover-section">
                    <ul>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sign-in", {
                              // state: { selectedTab: "2" },
                            });
                          }}
                        >
                          <span>Ezugi</span>
                          <img src={Ezugi} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sign-in", {
                              // state: { selectedTab: "3" },
                            });
                          }}
                        >
                          <span>Evolution</span>
                          <img src={Evolution} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sign-in", {
                              // state: { selectedTab: "4" },
                            });
                          }}
                        >
                          <span>Vivo</span>
                          <img src={Vivo} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sign-in", {
                              // state: { selectedTab: "1" },
                            });
                          }}
                        >
                          <span>Supernowa</span>
                          <img src={Supernowa} />
                          <p>Go</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <Nav.Link href="/sign-in">Slots</Nav.Link>
              </li>
              <li>
                <Nav.Link href="/casino/evolution">Live Casino</Nav.Link>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
