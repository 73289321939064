import React from "react";

export default function BetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.068"
      height="25.068"
      viewBox="0 0 25.068 25.068"
    >
      <g
        id="Group_1183"
        data-name="Group 1183"
        transform="translate(-249.738 -149.716)"
      >
        <path
          id="Path_2813"
          data-name="Path 2813"
          d="M260.252,163.3c.051,0,.087,0,.122,0a10.074,10.074,0,0,0,1.6-.076,2.834,2.834,0,0,0,1.53-.7,1.538,1.538,0,0,0,.518-.894.544.544,0,0,0,.009-.069.368.368,0,0,0,0-.052h-4.154l.355-.531c.085-.128.174-.254.256-.385a.155.155,0,0,1,.152-.08q1.556,0,3.112,0h.129a2.122,2.122,0,0,0-.129-.209,1.526,1.526,0,0,0-.682-.464,5.2,5.2,0,0,0-1.285-.282,15.432,15.432,0,0,0-1.863-.069c-.019,0-.038,0-.072-.005l.265-.376c.147-.206.3-.409.438-.618a.162.162,0,0,1,.155-.076h6.876c-.078.128-.143.24-.212.348-.151.237-.306.474-.456.712a.14.14,0,0,1-.135.073c-.52,0-1.04,0-1.56,0h-.11c.1.187.192.367.281.552a2.036,2.036,0,0,1,.117.3.1.1,0,0,0,.118.086c.605,0,1.211.005,1.817.009h.1c-.066.1-.12.193-.179.28-.172.259-.348.516-.518.776a.121.121,0,0,1-.118.064c-.339,0-.678,0-1.017,0-.066,0-.1.01-.12.088a2.767,2.767,0,0,1-1.532,1.843,5.223,5.223,0,0,1-1.578.467l-.388.059,4.125,4.775c-.046,0-.073.01-.1.01-.57,0-1.139,0-1.709,0a.211.211,0,0,1-.138-.067q-.784-.869-1.563-1.744-1.194-1.335-2.389-2.668a.246.246,0,0,1-.07-.182C260.254,163.9,260.252,163.609,260.252,163.3Z"
          transform="translate(-1.445 -0.699)"
          fill="#2db2ff"
        />
        <circle
          id="Ellipse_141"
          data-name="Ellipse 141"
          cx="12.034"
          cy="12.034"
          r="12.034"
          transform="translate(250.238 150.216)"
          fill="none"
          stroke="#2db2ff"
          strokeWidth="1"
        />
        <circle
          id="Ellipse_142"
          data-name="Ellipse 142"
          cx="9.068"
          cy="9.068"
          r="9.068"
          transform="translate(253.204 153.182)"
          fill="none"
          stroke="#2db2ff"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
