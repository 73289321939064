import Card32new from "../../assets/images/32card.png";
export const casinoTabs = {
  LiveDealer: {
    // DragonTiger: {
    //   href: "/casino/ezugi/DragonTiger",
    //   code: "150",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img1.jpg", alt: "" },
    // },
    // DragonTigerLive: {
    //   href: "/casino/ezugi/DragonTigerLive",
    //   code: "1000074",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img3.jpg", alt: "" },
    // },
    // AndarBahar: {
    //   href: "/casino/ezugi/AndarBahar",
    //   code: "228000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img4.jpg", alt: "" },
    // },
    // LightningRoulette: {
    //   href: "/casino/ezugi/LightningRoulette",
    //   code: "1000092",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img5.jpg", alt: "" },
    // },
    // Teenpatti: {
    //   href: "/casino/ezugi/Teenpatti",
    //   code: "227103",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img6.jpg", alt: "" },
    // },
    // SuperAndarBahar: {
    //   href: "/casino/ezugi/SuperAndarBahar",
    //   code: "328000",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img9.jpg", alt: "" },
    // },
    // BaccaratA: {
    //   href: "/casino/ezugi/BaccaratA",
    //   code: "1000012",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img18.jpg", alt: "" },
    // },
    // Blackjack: {
    //   href: "/casino/ezugi/Blackjack",
    //   code: "1",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img21.jpg", alt: "" },
    // },
    // BlackjackLive: {
    //   href: "/casino/ezugi/BlackjackLive",
    //   code: "1000064",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img22.jpg", alt: "" },
    // },
    // CrazyTime: {
    //   href: "/casino/ezugi/CrazyTime",
    //   code: "1000148",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img23.jpg", alt: "" },
    // },
    // BaccaratA: {
    //   href: "/casino/ezugi/BaccaratA",
    //   code: "1000012",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img24.jpg", alt: "" },
    // },
    // GoldenBaccarat: {
    //   href: "/casino/ezugi/GoldenBaccarat",
    //   code: "1000355",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img28.jpg", alt: "" },
    // },
    // LightningBlackjack: {
    //   href: "/casino/ezugi/LightningBlackjack",
    //   code: "1000358",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img46.jpg", alt: "" },
    // },
    // LightningDice: {
    //   href: "/casino/ezugi/LightningDice",
    //   code: "1000076",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img47.jpg", alt: "" },
    // },
    // LightningBaccarat: {
    //   href: "/casino/ezugi/LightningBaccarat",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/dealer-img48.jpg", alt: "" },
    // },
    // player3: {
    //   href: "/Worldcasino",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/live-dealer-supernova.png", alt: "" },
    // },
    // kingRace: {
    //   href: "/Worldcasino",
    //   code: "1000149",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "",
    //   cover: { src: "./images/dealer/live-dealer-supernova2.png", alt: "" },
    // },
    SpeedRoulette1: {
      href: "/EzugiPage",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dealer/live-dealer-ezugi11.png", alt: "" },
    },
    DragonRiger1: {
      href: "/EzugiPage",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dealer/dragon-tiger.png", alt: "" },
    },
    UltimateRoulette: {
      href: "/EzugiPage",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dealer/ultimate-roulette.png", alt: "" },
    },
    FootballStudio: {
      href: "/EvolutionPage",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dealer/live-dealer-evolution11.png", alt: "" },
    },
    DreamCatcher: {
      href: "/EvolutionPage",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dealer/live-dealer-evolution21.png", alt: "" },
    },
    InfiniteBlackjack: {
      href: "/EvolutionPage",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dealer/Infinite-blackjack.png", alt: "" },
    },
  },

  PopularGames: {
    DreamCatcher1: {
      href: "/casino/ezugi/DreamCatcher1",
      code: "1000077",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/dream-catcher.png", alt: "" },
    },
    CrazyTime: {
      href: "/casino/ezugi/CrazyTime",
      code: "1000148",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Crazy-time.png", alt: "" },
    },
    MegaBall: {
      href: "/casino/ezugi/MegaBall",
      code: "1000141",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Megaball.png", alt: "" },
    },
    Lucky7: {
      href: "/casino/ezugi/Lucky7",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Lucky-7.png", alt: "" },
    },
    DragonTiger1: {
      href: "/casino/ezugi/DragonTiger1",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/dragon-tiger.png", alt: "" },
    },
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Speed-Roulette.png", alt: "" },
    },
    Teenpatticard: {
      href: "/casino/ezugi/Teenpatticard",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/Teenpatti-card.png", alt: "" },
    },
    cards32: {
      href: "/casino/ezugi/cards32",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/popular/32-cards.png", alt: "" },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/twinspin.png", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/slots/ezugi-slots/gonzo.png",
        alt: "",
      },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/stones.png", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/secrets.png", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/wheel.png", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/10001Nights.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Cash_Ultimate.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/Cash_Volt.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/slots/ezugi-slots/Dazzle_Me_Megaways.png",
        alt: "",
      },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: {
        src: "./images/slots/ezugi-slots/Divine_Fortune_Megaways.png",
        alt: "",
      },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/FortuneHouse.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/ezugi-slots/777_Strike.png", alt: "" },
    },

    Coins12: {
      href: "/casino/worldcasino/Coins12",
      code: "WAZ-12coinsholdthejackpotcashinfinity",
      casino: "wco",
      provider: "QT",
      homeUrl: "",
      cover: {
        src: "./images/slots/ezugi-slots/Willys_Hot_Chillies.png",
        alt: "",
      },
    },
  },
  roulettetab: {
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Sped-roulteee.png", alt: "" },
      name: "Speed Roulette",
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Diamond.png", alt: "" },
      name: "Diamond Roulette",
    },
    DoubleBallRoulette: {
      href: "/casino/ezugi/DoubleBallRoulette",
      code: "1000084",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Double-ball.png", alt: "" },
      name: "Bouble Ball Roulette",
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/NamasteRoulette.png", alt: "" },
      name: "Namaste Roulette",
    },
    InstantRoulette: {
      href: "/casino/ezugi/InstantRoulette",
      code: "1000146",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Instant-Live.png", alt: "" },
      name: "Instant Roulette",
    },
    LightRoulette: {
      href: "/casino/ezugi/LightRoulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Lightning.png", alt: "" },
      name: "Lightning Roulette",
    },
    SalonPriveRoulette: {
      href: "/casino/ezugi/SalonPriveRoulette",
      code: "1000102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Salon-Prive.png", alt: "" },
      name: "Salon Prive Roulette",
    },
    TurkeRoulette: {
      href: "/casino/ezugi/TurkeRoulette",
      code: "1000316",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Lightininh-turcki.png", alt: "" },
      name: "Turke Roulette",
    },
    UltimateRoulette: {
      href: "/casino/ezugi/UltimateRoulette",
      code: "541000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/Ultimate-roulette.png", alt: "" },
      name: "Ultimate Roulette",
    },
    LightRouletteFirstPerosn: {
      href: "/casino/ezugi/LightRouletteFirstPerosn",
      code: "1000120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/casino01111.png", alt: "" },
      name: "Lightning Roulette First Person",
    },

    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/FiestaRoulette.png", alt: "" },
      name: "Fiesta Roulette",
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/PrestigeAutoRoulette.png", alt: "" },
      name: "Prestige Auto Roulette",
    },
  },
  baccarattab: {
    LightBaccarat: {
      href: "/casino/ezugi/LightBaccarat",
      code: "1000149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Lightning.png", alt: "" },
      name: "Lightning Baccarat",
    },
    SpeedBaccaratA: {
      href: "/casino/ezugi/SpeedBaccaratA",
      code: "1000021",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Speed-BacaratA.png", alt: "" },
      name: "Speed Baccarat A",
    },
    SpeedBaccaratb: {
      href: "/casino/ezugi/SpeedBaccaratb",
      code: "1000022",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Bacart-B.png", alt: "" },
      name: "Speed Baccarat B",
    },
    SpeedBaccaratH: {
      href: "/casino/ezugi/SpeedBaccaratH",
      code: "1000028",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/speedbaccaratH.png", alt: "" },
      name: "Speed Baccarat H",
    },
    NocommiBaccarat: {
      href: "/casino/ezugi/NocommiBaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Baract.png", alt: "" },
      name: "No Commission Baccarat",
    },
    BaccaratKnockout: {
      href: "/casino/ezugi/BaccaratKnockout",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Baccarat-Knockout.png", alt: "" },
      name: "Baccarat Knockout",
    },

    Marina01baccarat: {
      href: "/casino/ezugi/Marina01baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/CasinoMarinaBaccarat1.png", alt: "" },
      name: "Casino Marina Baccarat",
    },
    Salsabaccarat: {
      href: "/casino/ezugi/Salsabaccarat",
      code: "41100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Salsabaccarat.png", alt: "" },
      name: "Salsa Baccarat",
    },
    BaccaratSuperSix: {
      href: "/casino/ezugi/BaccaratSuperSix",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/Baccarat-Super-Six.png", alt: "" },
      name: " Baccarat Super Six",
    },
    CricketWar: {
      href: "/casino/ezugi/CricketWar",
      code: "45100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/CricketWar.png", alt: "" },
      name: " Cricket War",
    },
    SpeedCricketWar: {
      href: "/casino/ezugi/SpeedCricketWar",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/SpeedCricketBaccarat.png", alt: "" },
      name: "Speed Cricket Baccarat",
    },
    Marina03baccarat: {
      href: "/casino/ezugi/Marina03baccarat",
      code: "32102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/casinomarinabaccarat3.png", alt: "" },
      name: "Casino Marina Baccarat 3",
    },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/Andar-Bahar.png", alt: "" },
      name: "Andar Bahar",
    },
    OTTAndarBahar: {
      href: "/casino/ezugi/OTTAndarBahar",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/Andar-Bahar-ott.png", alt: "" },
      name: "OTT Andar Bahar",
    },
    UltimateAndarBahar: {
      href: "/casino/ezugi/UltimateAndarBahar",
      code: "228100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/ultimateandarbahar.png", alt: "" },
      name: "Ultimate Andar Bahar",
    },
  },
  TeenPattitab: {
    CasinoHold: {
      href: "/casino/ezugi/CasinoHold",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/hold2.png", alt: "" },
      name: "Casino Hold'em",
    },
    CasinoHold2: {
      href: "/casino/ezugi/CasinoHold2",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/2handcasino.png", alt: "" },
      name: "2 Hand Casino Hold'em",
    },
    TexasHold: {
      href: "/casino/ezugi/TexasHold",
      code: "1000151",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Ultimate-texas.png", alt: "" },
      name: "Ultimate Texas Hold'em",
    },
    ThreeCard: {
      href: "/casino/ezugi/ThreeCard",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/threecardpoker.png", alt: "" },
      name: "Three Card Poker",
    },
    OneDayTeenPattiClassic: {
      href: "/casino/ezugi/OneDayTeenPattiClassic",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/onedayteenpatticlassic.png", alt: "" },
      name: "One Day Teen Patti Classic",
    },
    OneDayTeenPatti: {
      href: "/casino/ezugi/OneDayTeenPatti",
      code: "227102",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/onedayteenpatti.png", alt: "" },
      name: "One Day Teen Patti",
    },
    BetonTeenPatti: {
      href: "/casino/ezugi/BetonTeenPatti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/BetOnTeenPatti.png", alt: "" },
      name: "Bet on Teen Patti",
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack01.png", alt: "" },
    },
    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack02.png", alt: "" },
    },
    BlackjackPro: {
      href: "/casino/ezugi/BlackjackPro",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack03.png", alt: "" },
    },
    VIPBlackjack: {
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack04.png", alt: "" },
    },
    BlackjackAutoSplit: {
      href: "/casino/ezugi/BlackjackAutoSplit",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack05.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack06.png", alt: "" },
    },
    LiveBlackjack: {
      href: "/casino/ezugi/LiveBlackjack",
      code: "221",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack07.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack09.png", alt: "" },
    },
    DiamondBlackjack: {
      href: "/casino/ezugi/DiamondBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack010.png", alt: "" },
    },
    Rumba02Blackjack: {
      href: "/casino/ezugi/Rumba02Blackjack",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/ezugi/VipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    Vip02Blackjack: {
      href: "/casino/ezugi/Vip02Blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack013.png", alt: "" },
    },
    TurkishBlackjack: {
      href: "/casino/ezugi/TurkishBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack015.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/BlackjackLive",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    BlackjackTurkce: {
      href: "/casino/ezugi/BlackjackTurkce",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    PlatinumBlackjack: {
      href: "/casino/ezugi/PlatinumBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/32Cards.png", alt: "" },
      name: "32 Cards",
    },
  },
  sevenLuckytab: {
    sevenLucky: {
      href: "/casino/ezugi/sevenLucky",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/Lucky-7.png", alt: "" },
      name: "Lucky 7",
    },
  },

  sicbotab: {
    SicBo: {
      href: "/casino/ezugi/SicBo",
      code: "224000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/sicbo.png", alt: "" },
      name: "Sic Bo",
    },
    UltimateSicBo: {
      href: "/casino/ezugi/UltimateSicBo",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/ultimateSicbo.png", alt: "" },
      name: "Ultimate Sic Bo",
    },
  },

  spribe: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/Mine.png", alt: "" },
      name: "Mines",
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/Goal.png", alt: "" },
      name: "Goal",
    },
    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/Dice.png", alt: "" },
      name: "Dice",
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/Aviator.png", alt: "" },
      name: "Aviator",
    },
    plinko: {
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "",
      cover: { src: "./images/spribe/Plinko.png", alt: "" },
      name: "Plinko",
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "",
      cover: { src: "./images/spribe/MiniRoulette.png", alt: "" },
      name: "Mini Roulett",
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "",
      cover: { src: "./images/spribe/Hilo.png", alt: "" },
      name: "Hilo",
    },
  },
  ezugi: {
    cardsthirty2: {
      href: "/casino/ezugi/cardsthirty2",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/32card.png", alt: "" },
    },
  },

  spribe: {
    href: "/casino/sprie",
    code: "",
    provider: "spribe",
    homeUrl: "",
    games: {
      mines: {
        href: "/casino/spribe/mines",
        code: "mines",
        casino: "spribe",
        provider: "mines",
        homeUrl: "",
        cover: { src: "./images/spribe/02.jpg", alt: "" },
      },
      goal: {
        href: "/casino/spribe/goal",
        code: "goal",
        casino: "spribe",
        provider: "goal",
        homeUrl: "",
        cover: { src: "./images/spribe/06.png", alt: "" },
      },
      dice: {
        href: "/casino/spribe/dice",
        code: "dice",
        casino: "spribe",
        provider: "dice",
        homeUrl: "",
        cover: { src: "./images/spribe/07.jpg", alt: "" },
      },
      aviator: {
        href: "/casino/spribe/aviator",
        code: "aviator",
        casino: "spribe",
        provider: "aviator",
        homeUrl: "",
        cover: { src: "./images/spribe/03.png", alt: "" },
      },
      plinko: {
        href: "/casino/spribe/plinko",
        code: "plinko",
        casino: "spribe",
        provider: "plinko",
        homeUrl: "",
        cover: { src: "./images/spribe/08.jpg", alt: "" },
      },
      miniroulette: {
        href: "/casino/spribe/miniroulette",
        code: "mini-roulette",
        casino: "spribe",
        provider: "mini-roulette",
        homeUrl: "",
        cover: { src: "./images/spribe/04.png", alt: "" },
      },
      hilo: {
        href: "/casino/spribe/hilo",
        code: "hi-lo",
        casino: "spribe",
        provider: "hi-lo",
        homeUrl: "",
        cover: { src: "./images/spribe/05.png", alt: "" },
      },
    },
  },

  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },
};
