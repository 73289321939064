import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import RummyIcon from "../assets/svg/RummyIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
import LogoutIcon from "../assets/svg/LogoutIcon";
import GameShowIcon from "../assets/svg/GameShowIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
// import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Fbicon from "../assets/images/head-icon/facebook.png";
import Twitter from "../assets/images/head-icon/twitter.png";
import Telegram from "../assets/images/head-icon/telegram.png";
import Instagram from "../assets/images/head-icon/instagram.png";
import Mobile from "../assets/images/head-icon/mobile.png";
import { AiFillStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import numberWithCommas from "../utils/numbersWithComma";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import EzugiIcon from "../assets/images/betnew1.png";
import EvolutionIcon from "../assets/images/roulette.png";
import SlotsIcon from "../assets/images/jackpot1.png";
import HomeIcon1 from "../assets/images/homenew1.png";
import SportsIcon1 from "../assets/images/throw.png";
import CasinoIcon1 from "../assets/images/casino-roulette.png";
import { APP_CONST } from "../config/const";
import { socket } from "../utils/socketUtil";
import { Toaster } from "../utils/toaster";
import Loader from "../assets/images/games-tab/loader-new.gif";
import i18next, { t } from "i18next";

import AffilateIcon from "../assets/images/affiliate-marketing.png";
import { Container, Modal } from "react-bootstrap";

import MenuIcon1 from "../assets/images/icons/menuicon.png";

import CricketImgHover from "../assets/images/games/Cricket.png";
import SoccerImgHover from "../assets/images/games/Soccer.png";
import TennisImgHover from "../assets/images/games/Tennis.png";
import InplayImgHover from "../assets/images/games/Inplay.png";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";
import AppDownload from "../assets/images/icons/download1.png";
import bonus from "../assets/images/bonus.png";
import Ezugi from "../assets/images/games/ezugi.png";
import Evolution from "../assets/images/games/Evolution.png";
import Vivo from "../assets/images/games/Vivo.png";
import Supernowa from "../assets/images/games/Supernowa.png";

const HeaderAfterLogin = () => {
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const handleLanguageChange = (value) => {
    i18next.changeLanguage(value.target.value);
    window.location.reload(false);
  };
  const [showBetModal, setShowBetModal] = useState(false);
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    dispatch(getExposureAmount());
    dispatch(getBetMatches({ status: "pending" }));
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showToggle, setShowToggle] = useState(true);
  const [isLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    if (showLeftbarToggle) {
      document.body.classList.add("leftbat-opened");
    } else {
      document.body.classList.remove("leftbat-opened");
    }
  }, [showLeftbarToggle]);

  useEffect(() => {
    setShowToggle(window.screen.width > 991.98);
    return () => {};
  }, []);

  const handleExchangeLaunch = () => {
    setShowLoader(true);
    socket.emit("get:spbwicket9_lobby", {}, (res) => {
      if (res.data?.error === true) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
        return false;
      }
      setShowLoader(false);
      window.open(res?.data?.data?.lobbyUrl, "_blank");
    });

    return () => {
      socket.off("get:spbwicket9_lobby");
    };
  };
  return (
    <header className="header aftrlgn">
      <div className={isLoader ? "loading_img2" : "loading_img2 d-none"}>
        <img src={Loader} alt="Loader" />
      </div>

      <div className="top-head">
        <div className="tophead-left">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.FACEBOOK != ""
                    ? appDetails?.FACEBOOK
                    : "javascript:void(0)"
                }
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src={Fbicon} />
                <p>Facebook</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                // href={
                //   appDetails?.TWITTER != ""
                //     ? appDetails?.TWITTER
                //     : "javascript:void(0)"
                // }
                // target={appDetails?.TWITTER != "" ? "_blank" : ""}
              >
                <img src={Twitter} />
                <p>Twitter</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != ""
                    ? appDetails?.INSTATGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} />
                <p>Instagram</p>
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.TELEGRAM != ""
                    ? appDetails?.TELEGRAM
                    : "javascript:void(0)"
                }
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} />
                <p>Telegram Channel</p>
              </a>
            </li>

            <li>
              <a href="#" onClick={handleShow1}>
                <img src={Mobile} />
                <p>Message Us</p>
              </a>
            </li>
          </ul>
        </div>
        <div className="tophead-right d-none">
          <div className="time-date">
            <span>May 26, 2023 at 1:57:22 PM</span>
          </div>
          <div className="language">
            <div className="language-option">
              <select
                className="language-select btn-outline-secondary beforelogin-language"
                value={window.localStorage.i18nextLng}
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="arab">عربى</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        className="app-modal"
      >
        <Modal.Body closeButton>
          <div className="app-installed">
            <div className="app-left-part">
              <Button variant="secondary" onClick={handleClose1}>
                X
              </Button>
              <div className="app-body">
                <a className="app-name">
                  <h5>Crixbet</h5>
                  <div className="app-rate">
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <AiFillStar />
                    <BsStarHalf />
                  </div>
                  {/* <a
                    href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                    download="0TtatjwLdZwgvIkSmZxS.apk"
                  >
                    Download the app
                  </a> */}
                </a>
              </div>
            </div>

            {/* <div className="app-install-btn">
              <a
                href="./images/0TtatjwLdZwgvIkSmZxS.apk"
                download="0TtatjwLdZwgvIkSmZxS.apk"
              >
                <button type="button" className="installBtn">
                  INSTALL
                </button>
              </a>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-between align-items-center nav-head">
        <div className="d-flex align-items-center">
          <div className="leftbar_toggle d-xl-none">
            <Button
              variant="primary"
              onClick={() => setShowLeftbarToggle((s) => !s)}
            >
              {/* <MenuIcon /> */}
              <img src={MenuIcon1} />
            </Button>
          </div>
          <div className="logo d-none d-md-block">
            <a href="/">
              {appDetails?.LOGO_URL && (
                <img src={appDetails?.LOGO_URL} alt="Logo" />
              )}
            </a>
          </div>

          <Navbar expand="lg" className="d-none">
            <Container>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/betby">Cricket</Nav.Link>
                  <Nav.Link href="/sportsbook">Exchange</Nav.Link>
                  {/* <Nav.Link href="/betby">SportsBook</Nav.Link> */}
                  <Nav.Link href="/casino/ezugi">Casino</Nav.Link>
                  <Nav.Link href="/Slots">Slots</Nav.Link>
                  <Nav.Link href="/casino/evolution">Live Casino</Nav.Link>
                  <Nav.Link href="/casino/worldcasino">World Casino</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          {/* <Button variant="primary menu-btn" onClick={handleShow2}>
            <MenuIcon />
          </Button> */}
        </div>
        <div className="logo d-block d-md-none">
          <a href="/">
            {appDetails?.LOGO_URL && (
              <img src={appDetails?.LOGO_URL} alt="Logo" />
            )}
          </a>
        </div>
        <div className="headerRight">
          <div className="funds">
            <ul>
              {/* <li>
                      <a href="#">
                        Exch Bal:{" "}
                        <span>{numberWithCommas(wallet?.sports || 0)}</span>
                      </a>
                    </li> */}
              <li
                className="d-none d-md-block"
                onClick={() => {
                  navigate("/gatewaylist");
                }}
              >
                <Button variant="primary deposit-btn w-100">DEPOSIT</Button>
              </li>
              <li className="" href="">
                {/* <a href="#" download="" className="btn-primary btn">
                  App <img src={AppDownload} />
                </a> */}
                {/* <Button variant="primary deposit-btn w-100"></Button> */}
              </li>
              <li>
                {/* <a href="#">
                        <span className="d-none d-md-block pe-md-2">
                          Balance:
                        </span>{" "}
                        <span>
                          INR. {numberWithCommas(wallet?.balance || 0)}
                        </span>
                      </a> */}
                <Dropdown align="end" className="me-2 balance-web">
                  <Dropdown.Toggle variant="" id="dropdown-basic">
                    Balance: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="tel:{user?.mobileno}">
                      <b>{user?.mobileno}</b>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>Balance</h6>
                      <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="javascript:void(0)"
                      onClick={() => setShowBetModal(true)}
                    >
                      <h6>Exposure</h6>
                      <span>INR {numberWithCommas(exposureAmount || 0)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="javascript:void(0)">
                      <h6>Bonus</h6>
                      <span>INR {numberWithCommas(wallet?.coins)}</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="/gatewaylist" className="deposit">
                      Deposit
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>

          <Button variant="primary menu-btn" onClick={handleShow}>
            <MenuIcon />
          </Button>
        </div>
      </div>

      <div className="container d-none">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <div className="logo">
                  <a href="/home">
                    {appDetails?.LOGO_URL && <img src={appDetails?.LOGO_URL} />}
                  </a>
                </div>
                <Navbar expand="">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setShowToggle((s) => !s)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                      <Nav.Link href="/home" className="logoImg">
                        {appDetails?.LOGO_URL && (
                          <img src={appDetails?.LOGO_URL} />
                        )}
                      </Nav.Link>
                      <Nav.Link href="/home">
                        {/* <HomeIcon />  */}
                        <img src={HomeIcon1} />
                        Home
                      </Nav.Link>
                      <Nav.Link href="/sportsbook">
                        {/* <SportsIcon />  */}
                        <img src={SportsIcon1} />
                        Sports
                      </Nav.Link>
                      <Nav.Link href="/casino/worldcasino">
                        {/*<GamesIcon />*/}
                        <img src={CasinoIcon1} />
                        Casino
                      </Nav.Link>
                      <Nav.Link href="/casino/spribe/aviator">
                        <img src={SlotsIcon} />
                        Aviator
                      </Nav.Link>
                      <Nav.Link href="/casino/ezugi">
                        <img src={EzugiIcon} />
                        Ezugi
                      </Nav.Link>
                      <Nav.Link href="/casino/evolution">
                        <img src={EvolutionIcon} />
                        Evolution
                      </Nav.Link>

                      <a
                        href="mailto:parterns@Crixbet"
                        className="affiliate-link"
                      >
                        <img src={AffilateIcon} />
                        <span>
                          Affilate
                          <p>parterns@Crixbet</p>
                        </span>
                      </a>

                      {/* <Nav.Link href="/cashier">
                        <CashierIcon /> Account statement
                      </Nav.Link> */}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
              <div className="headerRight">
                <div className="funds">
                  <ul>
                    {/* <li>
                      <a href="#">
                        Exch Bal:{" "}
                        <span>{numberWithCommas(wallet?.sports || 0)}</span>
                      </a>
                    </li> */}
                    <li
                      className="d-md-none d-block"
                      onClick={() => {
                        navigate("/gatewaylist");
                      }}
                    >
                      <Button variant="primary deposit-btn w-100">
                        DEPOSIT
                      </Button>
                    </li>
                    <li>
                      {/* <a href="#">
                        <span className="d-none d-md-block pe-md-2">
                          Balance:
                        </span>{" "}
                        <span>
                          INR. {numberWithCommas(wallet?.balance || 0)}
                        </span>
                      </a> */}
                      <DropdownButton
                        id=""
                        title="Balance"
                        className="d-none d-md-block"
                      >
                        <Dropdown.Item href="#/action-1">
                          <div className="currency-head">
                            <div className="active currency-section">
                              <div className="currency-sign">
                                <span>₹</span>
                              </div>
                              <div className="currency-name">
                                <span>MAIN ACCOUNT (INR)</span>
                                <span>
                                  {numberWithCommas(wallet?.balance || 0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                        {/* <Dropdown.Item href="#/action-2">
                          <div className="currency-head">
                            <div className="currency-section">
                              <div className="currency-sign">
                                <span>₹</span>
                              </div>
                              <div className="currency-name">
                                <span>INR (INR)</span>
                                <span>0.01</span>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item> */}
                      </DropdownButton>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom_head">
        <Navbar expand="lg">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <li>
                <Nav.Link href="/sportsbook">Exchange </Nav.Link>
                <div className="on-hover-section">
                  <div className="sports-hover-section">
                    <ul>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "2" },
                            });
                          }}
                        >
                          <span>Cricket</span>
                          <img src={CricketImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "3" },
                            });
                          }}
                        >
                          <span>Soccer</span>
                          <img src={SoccerImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "4" },
                            });
                          }}
                        >
                          <span>Tennis</span>
                          <img src={TennisImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/sportsbook", {
                              state: { selectedTab: "1" },
                            });
                          }}
                        >
                          <span>Inplay</span>
                          <img src={InplayImgHover} />
                          <p>Go</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              {/* <li>
                <Nav.Link href="/betby">Sportsbook</Nav.Link>
              </li> */}
              <li>
                <Nav.Link href="/casino/ezugi">Casino</Nav.Link>
                <div className="on-hover-section">
                  <div className="sports-hover-section">
                    <ul>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/casino/ezugi", {
                              // state: { selectedTab: "2" },
                            });
                          }}
                        >
                          <span>Ezugi</span>
                          <img src={Ezugi} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/casino/evolution", {
                              // state: { selectedTab: "3" },
                            });
                          }}
                        >
                          <span>Evolution</span>
                          <img src={Evolution} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/casino/vivo", {
                              // state: { selectedTab: "4" },
                            });
                          }}
                        >
                          <span>Vivo</span>
                          <img src={Vivo} />
                          <p>Go</p>
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={() => {
                            navigate("/casino/supernowa", {
                              // state: { selectedTab: "1" },
                            });
                          }}
                        >
                          <span>Supernowa</span>
                          <img src={Supernowa} />
                          <p>Go</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <Nav.Link href="/Slots">Slots</Nav.Link>
              </li>
              <li>
                <Nav.Link href="/casino/evolution">Live Casino</Nav.Link>
              </li>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <ProfileIcon />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-3">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                INR. {numberWithCommas(wallet?.balance || 0)}
              </div>
            </div>
            <div className="col-6">
              <div className="balance mb-3"> Bonus</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                INR. {numberWithCommas(wallet?.coins)}
              </div>
            </div>

            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="outline-secondary w-100">Withdraw</Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/bonus" className="bonus_left">
                <img src={bonus} />
                <span>Bonus</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            {/* <li>
              <a href="https://t.me/team1stbetasia" target="_blank">
                <TelegramIcon />
              </a>
            </li> */}

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas show={show2} onHide={handleClose2} placement={"end"}>
        <Offcanvas.Header
          closeButton="closeButton"
          closeVariant="white"
        ></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-3">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                INR. {numberWithCommas(wallet?.balance || 0)}
              </div>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="outline-secondary w-100">Withdraw</Button>
            </div>
            <div
              className="col-6"
              onClick={() => {
                navigate("/gatewaylist");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            {/* <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li> */}
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a href="https://t.me/team1stbetasia" target="_blank">
                <TelegramIcon />
              </a>
            </li>

            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails.WHATS_APP_URL}`
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </a>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default HeaderAfterLogin;
